import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';
import axios from 'axios';

import type { RootState } from '../store';
import type { Operation } from './operationSlice';
import type { Market } from './marketSlice';
import type { LabelValue } from './labelSlice';
import type { BudgetLineForDropdown } from './budgetLineSlice';
import type {
    SpendingRythm,
    ServerError,
    PriceVariationSettings,
    SimulationTask,
    SimulationStatus,
} from './common';
import { errorHandler } from './common';
import type { MarketInvoice, EngagementInvoice } from './invoiceSlice';
import type { ExpenseByMonth } from './expenseByMonthSlice';
import type { IUserProfile } from './userSlice';

export type EngagementType =
    | 'market'
    | 'amendment'
    | 'service_order'
    | 'pursuit_descision'
    | 'other';

export type EcheancierMode = 'manual_entry' | 'automatic_calculation';

export const MANUAL_ENTRY = 'manual_entry';
export const AUTOMATIC_CALCULATION = 'automatic_calculation';

export type Engagement = {
    id: number;
    label: string;
    marketId: number;
    spendingRythmId?: number | null;
    budgetLineId: number;
    comment: string;
    engagementType: EngagementType;
    amountHt: number;
    taxes: number;
    amountTtc: number;
    revisionsAmountHt: number;
    revisionsTaxes: number;
    revisionsAmountTtc: number;
    actualisationsAmountHt: number;
    actualisationsTaxes: number;
    actualisationsAmountTtc: number;
    variationsAmountHt: number;
    variationsTaxes: number;
    variationsAmountTtc: number;
    notificationDate: string;
    startDate: string;
    durationInDays: number;
    endDate: string;
    depreciationPeriod?: string | null;
    createdAt: string;
    order: number;
    tvaCodeId: number;
    downPaymentPercentage: number;
    downPaymentAmountTtc: number;
    advancePaymentPercentage: number;
    advancePaymentAmountTtc: number;
    inferiorRecoveryPercentage: number;
    superiorRecoveryPercentage: number;
    holdbackPercentage: number;
    warrantiesTotalTtc: number;
    labelValues?: LabelValue[];
    priceVariationSettings?: PriceVariationSettings;
    spendingRythm?: SpendingRythm;
    externalNumber?: string | null;
    isSimulated: boolean;
    groupCode: string | null;
    hasEngagementInvoices: boolean;
    echeancierLastCalculationAt?: string | null;
    lastUpdatedAtForElk: string;
    echeancierMode?: EcheancierMode;
    matched: boolean;
};

export type EcheanceEngagementInvoice = {
    marketInvoiceNumber: MarketInvoice['invoiceNumber'];
    avancementsAmountHt: EngagementInvoice['avancementsAmountHt'];
};

export interface IEcheanceEngagementInvoiceFormData
    extends Omit<EcheanceEngagementInvoice, 'marketInvoiceNumber'> {
    marketInvoiceNumber: EcheanceEngagementInvoice['marketInvoiceNumber'] | string | null;
}

export type EcheanceEngagement = {
    number: number | null;
    month: string;
    amountHt: ExpenseByMonth['amountHt'];
    suggestionHt: ExpenseByMonth['amountHt'];
    engagementInvoices: EcheanceEngagementInvoice[];
};

export interface IEcheanceEngagementFormData
    extends Omit<EcheanceEngagement, 'number' | 'amountHt'> {
    number: EcheanceEngagement['number'] | string;
    amountHt: EcheanceEngagement['amountHt'] | string;
    engagementAvancementHt: EngagementInvoice['avancementsAmountHt'];
}

export type EcheancierEngagement = {
    echeances: EcheanceEngagement[];
    monthOfLatestEngagementInvoice: MarketInvoice['month'] | null;
    cumulatedAvancementHt: EngagementInvoice['currentCumulatedAvancementsAmountHt'];
    restToInvoiceHt: Engagement['amountHt'];
    updatedAt: string;
    updatedBy?: {
        firstName: IUserProfile['firstName'];
        lastName: IUserProfile['lastName'];
    };
};
export interface IEcheancierEngagementFormData extends Omit<EcheancierEngagement, 'echeances'> {
    echeances: IEcheanceEngagementFormData[];
}
export interface IEngagementFormData {
    id?: number;
    label: string;
    marketId: number;
    spendingRythmId?: number | null;
    budgetLineId: number;
    comment?: string;
    engagementType: EngagementType;
    amountHt: number | string | null;
    taxes: number;
    amountTtc: number | string;
    notificationDate: string;
    durationInWeeks?: number | null;
    depreciationPeriod?: string | null;
    createdAt?: string;
    order: number;
    tvaCodeId: number;
    downPaymentPercentage?: number | string | null;
    downPaymentAmountTtc?: number | string | null;
    advancePaymentPercentage?: number | string | null;
    advancePaymentAmountTtc?: number | string | null;
    inferiorRecoveryPercentage?: number | string | null;
    superiorRecoveryPercentage?: number | string | null;
    holdbackPercentage?: number | string | null;
    warrantiesTotalTtc?: number | null;
    labelValues: LabelValue[];
    isWarranty?: boolean;
    isAdvancePayment?: boolean;
    isDownPayment?: boolean;
    priceVariationSettings?: PriceVariationSettings;
    spendingRythm?: SpendingRythm;
    externalNumber?: string | null;
    isSimulated?: boolean;
    actualisationsAmountHt?: number;
    revisionsAmountHt?: number;
    groupCode: string | null;
    echeancierMode?: EcheancierMode;
}
export const getEngagements = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    { data: Engagement[]; operationId: Operation['id']; marketId: Market['id'] },
    // First argument to the payload creator
    {
        operationId: Operation['id'];
        marketId: Market['id'];
    },
    {
        rejectValue: ServerError;
        state: RootState;
    }
>('ENGAGEMENT/GETALL', async ({ operationId, marketId }, { rejectWithValue }) => {
    try {
        const response = await axios.get(
            `/operations/${operationId}/markets/${marketId}/engagements`,
        );

        if (!response.data.data || !Array.isArray(response.data.data)) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }

        return { data: response.data.data, operationId, marketId };
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;
        if (error.response?.data.translationKey || error.response?.status === 499) {
            return rejectWithValue({
                translationKey: error.response.data.translationKey,
            });
        }
        console.error(err);
        return rejectWithValue({
            message: error.message,
            translationKey: 'errors.somethingWentWrong',
        });
    }
});

export const getEngagement = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    Engagement,
    // First argument to the payload creator
    { id: Engagement['id']; operationId: Operation['id']; marketId: Market['id'] },
    {
        rejectValue: ServerError;
    }
>('ENGAGEMENT/GET', async ({ id, operationId, marketId }, { rejectWithValue }) => {
    try {
        const response = await axios.get<{ data?: Engagement }>(
            `/operations/${operationId}/markets/${marketId}/engagements/${id}`,
        );

        if (!response.data.data) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }

        return response.data.data;
    } catch (err: unknown) {
        return rejectWithValue({
            message: 'Something went wrong.',
            translationKey: 'errors.somethingWentWrong',
        });
    }
});

export const createEngagement = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    Engagement,
    // First argument to the payload creator
    {
        engagement: IEngagementFormData;
        operationId: Operation['id'];
        marketId: Market['id'];
    },
    {
        rejectValue: ServerError;
        state: RootState;
    }
>(
    'ENGAGEMENT/POST',
    async ({ engagement, operationId, marketId }, { rejectWithValue, dispatch }) => {
        try {
            const response = await axios.post<{ data?: Engagement }>(
                `/operations/${operationId}/markets/${marketId}/engagements`,
                engagement,
            );

            if (!response.data.data) {
                return rejectWithValue({
                    message: 'No data returned',
                    translationKey: 'errors.noDataResponse',
                });
            }

            return response.data.data;
        } catch (err: unknown) {
            const error = err as AxiosError<ServerError>;

            if (error.response?.data.translationKey) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({
                message: 'Something went wrong.',
                translationKey: 'errors.somethingWentWrong',
            });
        }
    },
);

export const updateEngagement = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    Engagement,
    // First argument to the payload creator
    {
        engagement: IEngagementFormData;
        operationId: Operation['id'];
        marketId: Market['id'];
    },
    {
        rejectValue: ServerError;
    }
>('ENGAGEMENT/PUT', async ({ engagement, operationId, marketId }, { rejectWithValue }) => {
    try {
        const response = await axios.put<{ data?: Engagement }>(
            `/operations/${operationId}/markets/${marketId}/engagements/${engagement.id}`,
            engagement,
        );

        if (!response.data.data) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }

        return response.data.data;
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;
        if (error.response?.data.translationKey) {
            return rejectWithValue(error.response.data);
        }
        return rejectWithValue({
            message: 'Something went wrong.',
            translationKey: 'errors.somethingWentWrong',
        });
    }
});

export const deleteEngagement = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    { id: Engagement['id']; operationId: Operation['id']; marketId: Market['id'] },
    // First argument to the payload creator
    { id: Engagement['id']; operationId: Operation['id']; marketId: Market['id'] },
    {
        rejectValue: ServerError;
    }
>('ENGAGEMENT/DELETE', async ({ id, operationId, marketId }, { rejectWithValue }) => {
    try {
        await axios.delete<{ data?: Engagement['id'] }>(
            `/operations/${operationId}/markets/${marketId}/engagements/${id}`,
        );
        return { id, operationId, marketId };
    } catch (err: unknown) {
        const error = err as AxiosError<ServerError>;
        if (error.response?.data.translationKey) {
            return rejectWithValue(error.response.data);
        }
        return rejectWithValue({
            message: 'Something went wrong.',
            translationKey: 'errors.somethingWentWrong',
        });
    }
});

export const getBudgetLinesForEngagements = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    BudgetLineForDropdown[],
    // First argument to the payload creator
    { operationId: Operation['id'] },
    {
        rejectValue: ServerError;
    }
>('ENGAGEMENT/GET_BUDGET_LINES', async ({ operationId }, { rejectWithValue }) => {
    try {
        const response = await axios.get<{ data?: BudgetLineForDropdown[] }>(
            `/operations/${operationId}/budget-lines-dropdown`,
        );

        if (!response.data.data) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }

        return response.data.data;
    } catch (err: unknown) {
        return rejectWithValue({
            message: 'Something went wrong.',
            translationKey: 'errors.somethingWentWrong',
        });
    }
});

export const updateEngagementEcheancierMode = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    Engagement,
    // First argument to the payload creator
    {
        echeancierMode: Engagement['echeancierMode'];
        id: Engagement['id'];
        operationId: Operation['id'];
        marketId: Market['id'];
    },
    {
        rejectValue: ServerError;
    }
>(
    'ENGAGEMENT_ECHEANCIER_MODE/PUT',
    async ({ echeancierMode, id, operationId, marketId }, { rejectWithValue }) => {
        try {
            const response = await axios.put<{ data?: Engagement }>(
                `/operations/${operationId}/markets/${marketId}/engagements/${id}/echeancier-mode`,
                { echeancierMode },
            );

            if (!response.data.data) {
                return rejectWithValue({
                    message: 'No data returned',
                    translationKey: 'errors.noDataResponse',
                });
            }

            return response.data.data;
        } catch (err: unknown) {
            return rejectWithValue({
                message: 'Something went wrong.',
                translationKey: 'errors.somethingWentWrong',
            });
        }
    },
);

export const getEcheancierEngagement = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    EcheancierEngagement,
    // First argument to the payload creator
    { id: Engagement['id']; operationId: Operation['id']; marketId: Market['id'] },
    {
        rejectValue: ServerError;
    }
>('ECHEANCIER_ENGAGEMENT/GET', async ({ id, operationId, marketId }, { rejectWithValue }) => {
    try {
        const response = await axios.get<{ data?: EcheancierEngagement }>(
            `/operations/${operationId}/markets/${marketId}/engagements/${id}/echeancier`,
        );

        if (!response.data.data) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }

        return response.data.data;
    } catch (err: unknown) {
        return rejectWithValue({
            message: 'Something went wrong.',
            translationKey: 'errors.somethingWentWrong',
        });
    }
});

export const updateEcheancierEngagement = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    EcheancierEngagement,
    // First argument to the payload creator
    {
        echeancierEngagement: EcheancierEngagement;
        id: Engagement['id'];
        operationId: Operation['id'];
        marketId: Market['id'];
    },
    {
        rejectValue: ServerError;
    }
>(
    'ECHEANCIER_ENGAGEMENT/PUT',
    async ({ echeancierEngagement, id, operationId, marketId }, { rejectWithValue }) => {
        try {
            const response = await axios.put<{ data?: EcheancierEngagement }>(
                `/operations/${operationId}/markets/${marketId}/engagements/${id}/echeancier`,
                echeancierEngagement,
            );

            if (!response.data.data) {
                return rejectWithValue({
                    message: 'No data returned',
                    translationKey: 'errors.noDataResponse',
                });
            }

            return response.data.data;
        } catch (err: unknown) {
            return rejectWithValue({
                message: 'Something went wrong.',
                translationKey: 'errors.somethingWentWrong',
            });
        }
    },
);

export const createEngagementSimulation = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    void,
    // First argument to the payload creator
    {
        id: Engagement['id'];
        operationId: Operation['id'];
        marketId: Market['id'];
        task: SimulationTask;
    },
    {
        rejectValue: ServerError;
    }
>(
    'ENGAGEMENT_SIMULATION/POST',
    async ({ id, operationId, marketId, task }, { rejectWithValue }) => {
        try {
            await axios.post(
                `/operations/${operationId}/markets/${marketId}/engagements/${id}/simulation`,
                task,
            );
        } catch (err: unknown) {
            return rejectWithValue({
                message: 'Something went wrong.',
                translationKey: 'errors.somethingWentWrong',
            });
        }
    },
);

export const getEngagementSimulation = createAsyncThunk<
    // Return type of the payload creator (passed to fulfilled type)
    { status: SimulationStatus; engagementId: Engagement['id'] },
    // First argument to the payload creator
    {
        id: Engagement['id'];
        operationId: Operation['id'];
        marketId: Market['id'];
    },
    {
        rejectValue: ServerError;
    }
>('ENGAGEMENT_SIMULATION/GET', async ({ id, operationId, marketId }, { rejectWithValue }) => {
    try {
        const response = await axios.get(
            `/operations/${operationId}/markets/${marketId}/engagements/${id}/simulation`,
        );

        if (!response.data.data) {
            return rejectWithValue({
                message: 'No data returned',
                translationKey: 'errors.noDataResponse',
            });
        }

        return response.data.data;
    } catch (err: unknown) {
        return rejectWithValue({
            message: 'Something went wrong.',
            translationKey: 'errors.somethingWentWrong',
        });
    }
});

interface IEngagementState {
    engagementsById: Record<number, Engagement>;
    echeanciersEngagementByEngagementId: Record<number, EcheancierEngagement>;
    simulationStatusByEngagementId: Record<number, SimulationStatus>;
    loading: boolean;
    queryString: string;
    error: string | null;
    selectedMarketId: number | null;
    budgetLinesForDropdown: BudgetLineForDropdown[];
    getEngagementFulfilled: boolean;
    createEngagementFulfilled: boolean;
    engagementCreated: Engagement | null;
    updateEngagementFulfilled: boolean;
    engagementIdDeleted: number | null;
    createEngagementSimulationFulfilled: boolean;
    getEcheancierEngagementFulfilled: boolean;
    updateEcheancierEngagementFulfilled: boolean;
    getEngagementsLoading: boolean;
}

export const initialState: IEngagementState = {
    loading: false,
    engagementsById: {},
    echeanciersEngagementByEngagementId: {},
    simulationStatusByEngagementId: {},
    error: null,
    queryString: '',
    selectedMarketId: null,
    budgetLinesForDropdown: [],
    getEngagementFulfilled: false,
    createEngagementFulfilled: false,
    engagementCreated: null,
    updateEngagementFulfilled: false,
    engagementIdDeleted: null,
    createEngagementSimulationFulfilled: false,
    getEcheancierEngagementFulfilled: false,
    updateEcheancierEngagementFulfilled: false,
    getEngagementsLoading: false,
};

export const slice = createSlice({
    name: 'engagements',
    initialState,
    reducers: {
        resetEngagementDeleted(state) {
            state.engagementIdDeleted = null;
        },
        resetGetEngagementFulfilled(state) {
            state.getEngagementFulfilled = false;
        },
        resetGetEcheancierEngagementFulfilled(state) {
            state.getEcheancierEngagementFulfilled = false;
        },
        resetCreateEngagementSimulationFulfilled(state) {
            state.createEngagementSimulationFulfilled = false;
        },
        resetEngagementCreated(state) {
            state.engagementCreated = null;
        },
        resetEngagementSimulationStatus(state, action) {
            state.simulationStatusByEngagementId[action.payload.engagementId] = null;
        },
        resetUpdateEcheancierEngagementFulfilled(state) {
            state.updateEcheancierEngagementFulfilled = false;
        },
        resetUpdateEngagementFulfilled(state) {
            state.updateEngagementFulfilled = false;
        },
    },
    extraReducers(builder) {
        // Get all
        builder.addCase(getEngagements.pending, (state, { meta }) => {
            if (meta.arg.marketId !== state.selectedMarketId) {
                state.selectedMarketId = meta.arg.marketId;
                state.engagementsById = {};
                state.budgetLinesForDropdown = [];
            }
            state.engagementIdDeleted = null;
            state.loading = true;
            state.error = null;
            state.getEngagementsLoading = true;
        });
        builder.addCase(getEngagements.fulfilled, (state, { payload }) => {
            payload.data.forEach((item: Engagement) => {
                state.engagementsById[item.id] = item;
            });
            state.selectedMarketId = payload.marketId;
            state.error = null;
            state.loading = false;
            state.getEngagementsLoading = false;
        });
        builder.addCase(getEngagements.rejected, errorHandler());
        // Get one
        builder.addCase(getEngagement.pending, (state) => {
            state.loading = true;
            state.getEngagementFulfilled = false;
            state.engagementIdDeleted = null;
            state.error = null;
        });
        builder.addCase(getEngagement.fulfilled, (state, { payload }) => {
            state.engagementsById[payload.id] = payload;
            state.getEngagementFulfilled = true;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getEngagement.rejected, errorHandler());
        // Create
        builder.addCase(createEngagement.pending, (state) => {
            state.loading = true;
            state.createEngagementFulfilled = false;
            state.engagementCreated = null;
            state.error = null;
        });
        builder.addCase(createEngagement.fulfilled, (state, { payload }) => {
            state.error = null;
            state.engagementsById[payload.id] = payload;
            state.createEngagementFulfilled = true;
            state.engagementCreated = payload;
            state.loading = false;
        });
        builder.addCase(createEngagement.rejected, errorHandler());
        // Update
        builder.addCase(updateEngagement.pending, (state) => {
            state.loading = true;
            state.engagementIdDeleted = null;
            state.updateEngagementFulfilled = false;
            state.error = null;
        });
        builder.addCase(updateEngagement.fulfilled, (state, { payload }) => {
            state.engagementsById[payload.id] = payload;
            state.updateEngagementFulfilled = true;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(updateEngagement.rejected, errorHandler());
        // Delete
        builder.addCase(deleteEngagement.pending, (state) => {
            state.loading = true;
            state.engagementIdDeleted = null;
            state.error = null;
        });
        builder.addCase(deleteEngagement.fulfilled, (state, { payload }) => {
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete -- necessary for this case
            delete state.engagementsById[payload.id];
            state.error = null;
            state.engagementIdDeleted = payload.id;
            state.loading = false;
        });
        builder.addCase(
            deleteEngagement.rejected,
            errorHandler((state, { meta }) => {
                state.engagementIdDeleted = meta.arg.id;
            }),
        );
        // Get budget lines for engagements
        builder.addCase(getBudgetLinesForEngagements.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getBudgetLinesForEngagements.fulfilled, (state, { payload }) => {
            state.budgetLinesForDropdown = payload;
            state.error = null;
            state.loading = false;
        });
        builder.addCase(getBudgetLinesForEngagements.rejected, errorHandler());
        // Get echeancier engagement
        builder.addCase(getEcheancierEngagement.pending, (state) => {
            state.loading = true;
            state.error = null;
            state.getEcheancierEngagementFulfilled = false;
        });
        builder.addCase(getEcheancierEngagement.fulfilled, (state, { meta, payload }) => {
            state.echeanciersEngagementByEngagementId[meta.arg.id] = payload;
            state.getEcheancierEngagementFulfilled = true;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getEcheancierEngagement.rejected, errorHandler());
        // Update echeancier engagement
        builder.addCase(updateEcheancierEngagement.pending, (state) => {
            state.loading = true;
            state.error = null;
            state.updateEcheancierEngagementFulfilled = false;
        });
        builder.addCase(updateEcheancierEngagement.fulfilled, (state, { meta, payload }) => {
            state.echeanciersEngagementByEngagementId[meta.arg.id] = payload;
            state.loading = false;
            state.error = null;
            state.updateEcheancierEngagementFulfilled = true;
        });
        builder.addCase(updateEcheancierEngagement.rejected, errorHandler());
        // Update engagement echeancier mode
        builder.addCase(updateEngagementEcheancierMode.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(updateEngagementEcheancierMode.fulfilled, (state, { payload }) => {
            // Update only the `echeancierMode` field to not update `lastUpdatedAtForElk` immediately
            // which would trigger the simulation modale
            state.engagementsById[payload.id].echeancierMode = payload.echeancierMode;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(updateEngagementEcheancierMode.rejected, errorHandler());
        // Get simulation status
        builder.addCase(getEngagementSimulation.pending, (state) => {
            state.error = null;
        });
        builder.addCase(getEngagementSimulation.fulfilled, (state, { payload, meta }) => {
            if (payload.status) {
                state.simulationStatusByEngagementId[payload.engagementId] = payload.status;
            } else {
                state.simulationStatusByEngagementId[meta.arg.id] = null;
            }
            state.error = null;
        });
        builder.addCase(getEngagementSimulation.rejected, errorHandler());
        // Create simulation
        builder.addCase(createEngagementSimulation.pending, (state) => {
            state.error = null;
            state.createEngagementSimulationFulfilled = false;
        });
        builder.addCase(createEngagementSimulation.fulfilled, (state) => {
            state.error = null;
            state.createEngagementSimulationFulfilled = true;
        });
        builder.addCase(createEngagementSimulation.rejected, errorHandler());
    },
});

export const selectError = (state: RootState) => state.engagement.error;

export const selectEngagementIdDeleted = (state: RootState) => state.engagement.engagementIdDeleted;

export const selectEngagements = (state: RootState) => {
    const engagements = Object.values(state.engagement.engagementsById);
    // Sorting engagements by order value
    engagements.sort((a, b) => (a.order > b.order ? 1 : -1));

    return engagements;
};

export const selectGetEngagementsLoading = (state: RootState) =>
    state.engagement.getEngagementsLoading;

export const selectValidatedBudgetLines = (state: RootState) => {
    const lines = Object.values(state.engagement.budgetLinesForDropdown);

    return lines;
};

export const selectEngagement =
    (id: Engagement['id']) =>
    (state: RootState): Engagement | undefined =>
        state.engagement.engagementsById[id];

export const selectFirstEngagement = (state: RootState): Engagement | undefined => {
    const engagements = selectEngagements(state);
    const firstEngagement = engagements.length > 0 ? engagements[0] : undefined;
    return firstEngagement;
};

export const selectLastEngagement = (state: RootState): Engagement | undefined => {
    const engagements = selectEngagements(state);
    const lastEngagement = engagements.length > 0 ? engagements[engagements.length - 1] : undefined;
    return lastEngagement;
};

export const selectLastEngagementNotificationDate = (state: RootState) => {
    const lastEngagement = selectLastEngagement(state);
    return lastEngagement ? lastEngagement.notificationDate : undefined;
};

export const selectEcheancierEngagement =
    (id: Engagement['id']) =>
    (state: RootState): EcheancierEngagement | undefined =>
        state.engagement.echeanciersEngagementByEngagementId[id];

export const selectIsLoading = (state: RootState) => state.engagement.loading;

export const selectLastEngagementOrder = (state: RootState) => {
    const lastEngagement = selectLastEngagement(state);
    return lastEngagement ? lastEngagement.order : 0;
};

export const selectGetEngagementFulfilled = (state: RootState) =>
    state.engagement.getEngagementFulfilled;

export const selectCreateEngagementFulfilled = (state: RootState) =>
    state.engagement.createEngagementFulfilled;

export const selectEngagementCreated = (state: RootState) => state.engagement.engagementCreated;

export const selectUpdateEngagementFulfilled = (state: RootState) =>
    state.engagement.updateEngagementFulfilled;

export const selectUsedGroupCodes = (state: RootState) => {
    const engagementsArray = Object.values(state.engagement.engagementsById);
    const usedGroupCodes: string[] = engagementsArray.reduce(
        (uniqueGroupCodes: string[], engagement: Engagement) => {
            if (engagement.groupCode && uniqueGroupCodes.indexOf(engagement.groupCode) === -1) {
                uniqueGroupCodes.push(engagement.groupCode);
            }
            return uniqueGroupCodes;
        },
        [],
    );
    usedGroupCodes.sort((a, b) => a.localeCompare(b));
    return usedGroupCodes;
};

export const selectCreateEngagementSimulationFulfilled = (state: RootState) =>
    state.engagement.createEngagementSimulationFulfilled;

export const selectEngagementSimulationStatus = (
    engagementId: Engagement['id'] | undefined | null,
) =>
    function (state: RootState): SimulationStatus | null {
        if (engagementId && state.engagement.simulationStatusByEngagementId[engagementId]) {
            return state.engagement.simulationStatusByEngagementId[engagementId];
        }
        return null;
    };

export const selectGetEcheancierEngagementFulfilled = (state: RootState) =>
    state.engagement.getEcheancierEngagementFulfilled;

export const selectUpdateEcheancierEngagementFulfilled = (state: RootState) =>
    state.engagement.updateEcheancierEngagementFulfilled;

// Actions added into the `reducers` part
export const {
    resetEngagementDeleted,
    resetGetEngagementFulfilled,
    resetGetEcheancierEngagementFulfilled,
    resetCreateEngagementSimulationFulfilled,
    resetEngagementCreated,
    resetEngagementSimulationStatus,
    resetUpdateEcheancierEngagementFulfilled,
    resetUpdateEngagementFulfilled,
} = slice.actions;

export default slice.reducer;
